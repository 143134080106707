import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { editShareUrl } from "../utils/carApis";
import { toast } from "sonner";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../@/components/ui/form";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
import { shareUrlSchema } from "../utils/schema";
const EditShareUrl = ({
  setShareModalOpen,
  shareModalOpen,
  shareUrl,
  getShareUrl,
}: {
  setShareModalOpen: Dispatch<SetStateAction<boolean>>;
  shareModalOpen: boolean;
  shareUrl: string;
  getShareUrl: () => void;
}) => {
  const [isPending, setIsPending] = useState(false);

  const shareUrlForm = useForm<z.infer<typeof shareUrlSchema>>({
    resolver: zodResolver(shareUrlSchema),
  });

  const onSubmit = async (values: z.infer<typeof shareUrlSchema>) => {
    setIsPending(true);
    try {
      await editShareUrl(values.shareUrl);

      toast.success("Share Url Edited Successfully");
      getShareUrl();
      setIsPending(false);
      setShareModalOpen(false);
      shareUrlForm.reset();
    } catch (error: any) {
      toast.error(error.code);
      setIsPending(false);
    }
  };
  return (
    <Dialog
      open={shareModalOpen}
      onOpenChange={(open) => {
        setShareModalOpen(open);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editing Share URL</DialogTitle>
          <DialogDescription className="mt-10">
            Edit the Share URL that will be used in the app
          </DialogDescription>
        </DialogHeader>

        <Form {...shareUrlForm}>
          <form
            onSubmit={shareUrlForm.handleSubmit(onSubmit)}
            className="space-y-8"
          >
            <FormField
              control={shareUrlForm.control}
              name="shareUrl"
              render={({ field }: { field: any }) => (
                <FormItem>
                  <FormLabel>App Share URL</FormLabel>
                  <FormControl>
                    <Input placeholder={shareUrl} type="url" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" className="w-full" disabled={isPending}>
              {isPending ? "Saving..." : "Save"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EditShareUrl;
