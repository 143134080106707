import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";

export const signIn = async (values: { email: string; password: string }) => {
  return signInWithEmailAndPassword(auth, values.email, values.password);
};

export const signOut = async () => {
  return auth.signOut();
};
