import React, { Dispatch, SetStateAction, useState } from "react";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { carSchema } from "../utils/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { uploadCar } from "../utils/carApis";
import { toast } from "sonner";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../@/components/ui/form";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
const AddCar = ({
  setModalOpen,
  getCars,
}: {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  getCars: () => void;
}) => {
  const [isPending, setIsPending] = useState(false);

  const carForm = useForm<z.infer<typeof carSchema>>({
    resolver: zodResolver(carSchema),
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof carSchema>) => {
    setIsPending(true);
    try {
      if (!values.carImage) {
        toast.error("Select a car image");
        setIsPending(false);
        return;
      }

      await uploadCar(values);
      getCars();
      toast.success("Upload Successful");
      setIsPending(false);
      setModalOpen(false);
      carForm.reset();
    } catch (error: any) {
      toast.error(error.code);
      setIsPending(false);
    }
  };
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Add a Car</DialogTitle>
        <DialogDescription className="mt-10">
          Fill in the car information
        </DialogDescription>
      </DialogHeader>

      <Form {...carForm}>
        <form onSubmit={carForm.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={carForm.control}
            name="name"
            render={({ field }: { field: any }) => (
              <FormItem>
                <FormLabel>Car Name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter Car Name" type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={carForm.control}
            name="carImage"
            render={({
              field: { value, onChange, ...fieldProps },
            }: {
              field: any;
            }) => (
              <FormItem>
                <FormLabel>Select Car Image</FormLabel>
                <FormControl>
                  <Input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg, image/webp"
                    {...fieldProps}
                    onChange={(e) => {
                      onChange(e.target.files && e.target.files[0]);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit" className="w-full" disabled={isPending}>
            {isPending ? "Uploading..." : "Upload"}
          </Button>
        </form>
      </Form>
    </DialogContent>
  );
};

export default AddCar;
