import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { editGamePercentage } from "../utils/carApis";
import { toast } from "sonner";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../@/components/ui/form";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
import { gamePercentageSchema } from "../utils/schema";
const EditCorrectPercentage = ({
  setEditCorrectPercentageModalOpen,
  editCorrectPercentageModalOpen,
  correctPercentage,
  getCorrectPercentage,
}: {
  setEditCorrectPercentageModalOpen: Dispatch<SetStateAction<boolean>>;
  editCorrectPercentageModalOpen: boolean;
  correctPercentage: number;
  getCorrectPercentage: () => void;
}) => {
  const [isPending, setIsPending] = useState(false);

  const correctPercentageForm = useForm<z.infer<typeof gamePercentageSchema>>({
    resolver: zodResolver(gamePercentageSchema),
  });

  const onSubmit = async (values: z.infer<typeof gamePercentageSchema>) => {
    setIsPending(true);
    try {
      await editGamePercentage(values.gamePercentage);

      toast.success("Game correct percentage Edited Successfully");
      getCorrectPercentage();
      setIsPending(false);
      setEditCorrectPercentageModalOpen(false);
      correctPercentageForm.reset();
    } catch (error: any) {
      toast.error(error.code);
      setIsPending(false);
    }
  };
  return (
    <Dialog
      open={editCorrectPercentageModalOpen}
      onOpenChange={(open) => {
        setEditCorrectPercentageModalOpen(open);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editing Game Correct Percentage</DialogTitle>
          <DialogDescription className="mt-10">
            Edit the percentage that the user has to get to make the answer
            correct
          </DialogDescription>
        </DialogHeader>

        <Form {...correctPercentageForm}>
          <form
            onSubmit={correctPercentageForm.handleSubmit(onSubmit)}
            className="space-y-8"
          >
            <FormField
              control={correctPercentageForm.control}
              name="gamePercentage"
              render={({ field }: { field: any }) => (
                <FormItem>
                  <FormLabel>Percentage (in %)</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={correctPercentage}
                      type="number"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" className="w-full" disabled={isPending}>
              {isPending ? "Saving..." : "Save"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EditCorrectPercentage;
