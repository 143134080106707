import { Dispatch, SetStateAction, useState } from "react";
import { Button } from "../@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../@/components/ui/dialog";
import { CarData } from "../pages/Dashbaord";
import { deleteCar } from "../utils/carApis";
import { toast } from "sonner";

const DeleteCar = ({
  deleteModalOpen,
  setDeleteModalOpen,
  car,
  getCars,
}: {
  deleteModalOpen: boolean;
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  car: CarData;
  getCars: () => void;
}) => {
  const [deleting, setDeleting] = useState(false);
  const deleteTheCar = async () => {
    setDeleting(true);
    try {
      const res: any = await deleteCar(car);

      getCars();
      toast.success(res?.message);
      setDeleteModalOpen(false);
    } catch (error: any) {
      toast.error(error.code);
    } finally {
      setDeleting(false);
    }
  };
  return (
    <Dialog
      open={deleteModalOpen}
      onOpenChange={(open) => {
        setDeleteModalOpen(open);
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Delete car?</DialogTitle>
          <DialogDescription>
            Do you really want to delete {car?.name}?
          </DialogDescription>
        </DialogHeader>

        <img
          src={car?.image_url}
          alt={car?.name}
          className="h-[200px] w-[200px] rounded-xl object-contain m-auto  transition-all duration-300 hover:scale-125 hover:rotate-6 cursor-zoom-in"
        />

        <DialogFooter>
          <Button onClick={() => setDeleteModalOpen(false)} disabled={deleting}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={deleteTheCar}
            disabled={deleting}
          >
            {deleting ? "Deleting..." : "Yes, Delete"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteCar;
