import { z } from "zod";

export const authSchema = z.object({
  email: z.string().min(2).max(50).email(),
  password: z.string().min(5),
});

export const carSchema = z.object({
  carImage: z.instanceof(File).refine((file) => file.size < 5000000, {
    message: "Your image must be less than 5MB.",
  }),
  name: z.string().min(1),
});

export const editCarSchema = z.object({
  carImage: z
    .instanceof(File)
    .refine((file) => file.size < 5000000, {
      message: "Your image must be less than 5MB.",
    })
    .optional(),
  name: z.string().default("").optional(),
});

export const timerSchema = z.object({
  time: z.coerce.number(),
});

export const shareUrlSchema = z.object({
  shareUrl: z.string().min(1).url(),
});

export const gamePercentageSchema = z.object({
  gamePercentage: z.coerce.number(),
});
