import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "./@/components/ui/sonner";
import Dashbaord from "./pages/Dashbaord";
import Auth from "./pages/Auth";

function App() {
  return (
    <Router>
      <Toaster richColors={true} />
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/dashboard" element={<Dashbaord />} />
      </Routes>
    </Router>
  );
}

export default App;
