import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "./firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { CarData } from "../pages/Dashbaord";

const uploadCarImage = async (values: { carImage?: any; name: string }) => {
  const carImageRef = ref(
    storage,
    `car images/${values.name}.${values.carImage.type.split("/")[1]}`
  );

  try {
    await uploadBytes(carImageRef, values.carImage).catch((error) => {
      return error;
    });

    return await getDownloadURL(
      ref(
        storage,
        `car images/${values.name}.${values.carImage.type.split("/")[1]}`
      )
    );
  } catch (error) {
    return error;
  }
};

export const uploadCar = async (values: { carImage?: any; name: string }) => {
  try {
    const url = await uploadCarImage(values);

    return await setDoc(doc(db, "cars", values.name), {
      name: values.name,
      deleted: false,
      image_url: url,
      id: values.name,
    });
  } catch (error) {
    return error;
  }
};

export const getAllCars = async () => {
  try {
    let cars: CarData[] = [
      {
        name: "",
        image_url: "",
        deleted: false,
      },
    ];
    const q = query(collection(db, "cars"), where("deleted", "==", false));

    const querySnapshot = await getDocs(q);
    cars = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      cars.push({
        name: data.name,
        image_url: data.image_url,
        deleted: data.deleted,
        id: data?.id,
      });
    });
    return cars;
  } catch (error) {
    return error;
  }
};

export const deleteCar = async (car: CarData) => {
  try {
    const carRef = doc(db, "cars", car?.id || car.name);

    await updateDoc(carRef, {
      deleted: true,
    });

    return {
      message: "Car Deleted Successfully",
    };
  } catch (error) {
    return error;
  }
};

export const editCar = async (values: {
  carImage?: any;
  name: string;
  id?: string;
}) => {
  try {
    const carRef = doc(db, "cars", values?.id || values.name);

    console.log(values);

    if (!values.carImage) {
      return await updateDoc(carRef, {
        id: values?.id || values.name,
        name: values.name,
      });
    }

    const url = await uploadCarImage(values);

    return await updateDoc(carRef, {
      id: values?.id || values.name,
      name: values.name,
      image_url: url,
    });
  } catch (error) {
    return error;
  }
};

export const getTheTimer = async () => {
  try {
    let timer = 0;
    const q = query(collection(db, "time"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      timer = data.time;
    });
    return timer;
  } catch (error) {
    return error;
  }
};

export const editTimer = async (time: number) => {
  try {
    let id = "";
    const q = query(collection(db, "time"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      id = doc.id;
    });

    const timerRef = doc(db, "time", id);

    await updateDoc(timerRef, { time })
      .then(() => {
        return true;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};

export const getTheShareUrl = async () => {
  try {
    let shareUrl = "";
    const q = query(collection(db, "shareUrl"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      shareUrl = data.shareUrl;
    });
    return shareUrl;
  } catch (error) {
    return error;
  }
};

export const editShareUrl = async (shareUrl: string) => {
  try {
    let id = "";
    const q = query(collection(db, "shareUrl"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      id = doc.id;
    });

    const timerRef = doc(db, "shareUrl", id);

    await updateDoc(timerRef, { shareUrl })
      .then(() => {
        return true;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};

export const getTheGamePercentage = async () => {
  try {
    let percentage = 0;
    const q = query(collection(db, "correctPercentage"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      percentage = data.percent;
    });
    return percentage;
  } catch (error) {
    return error;
  }
};
export const editGamePercentage = async (gamePercentage: number) => {
  try {
    let id = "";
    const q = query(collection(db, "correctPercentage"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      id = doc.id;
    });

    const gamePercentageRef = doc(db, "correctPercentage", id);

    await updateDoc(gamePercentageRef, { percent: gamePercentage })
      .then(() => {
        return true;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};
