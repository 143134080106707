import React, { useLayoutEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { authSchema } from "../utils/schema";
import { Button } from "../@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../@/components/ui/form";
import { Input } from "../@/components/ui/input";
import logo from "../assets/logo.svg";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { signIn } from "../utils/auth";
const Auth = () => {
  const [isPending, setIsPending] = useState(false);
  const authForm = useForm<z.infer<typeof authSchema>>({
    resolver: zodResolver(authSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const navigate = useNavigate();

  const onSubmit = async (values: z.infer<typeof authSchema>) => {
    setIsPending(true);
    try {
      const res: any = await signIn(values);
      toast.success("Authentication Successful");
      sessionStorage.setItem("car-guesser-user", JSON.stringify(res.user));
      navigate("/dashboard");
    } catch (error: any) {
      toast.error(error.code);
    }
    setIsPending(false);
  };

  useLayoutEffect(() => {
    const user =
      JSON.parse(sessionStorage.getItem("car-guesser-user") || "null") || null;

    if (user) {
      navigate("/dashboard");
    }
  });

  return (
    <div className="w-full h-[100vh] flex items-center justify-center p-4">
      <div className="shadow-xl px-4 py-10 rounded-xl flex-col gap-4 flex">
        <p className="text-2xl font-semibold text-slate-900 text-center flex flex-row gap-4 items-center justify-center">
          <img src={logo} className="w-10 h-10 object-cover" alt="" />
          <span>Admin Login</span>
        </p>

        <Form {...authForm}>
          <form
            onSubmit={authForm.handleSubmit(onSubmit)}
            className="space-y-8 md:w-96 w-full"
          >
            <FormField
              control={authForm.control}
              name="email"
              render={({ field }: { field: any }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter Your Email"
                      type="email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={authForm.control}
              name="password"
              render={({ field }: { field: any }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter Your Password"
                      type="password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="w-full" disabled={isPending}>
              {isPending ? "Logging In..." : "Log In"}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default Auth;
