import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { editCar } from "../utils/carApis";
import { toast } from "sonner";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../@/components/ui/form";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
import { CarData } from "../pages/Dashbaord";
import { editCarSchema } from "../utils/schema";
const EditCar = ({
  setEditModalOpen,
  getCars,
  car,
  editModalOpen,
}: {
  setEditModalOpen: Dispatch<SetStateAction<boolean>>;
  getCars: () => void;
  editModalOpen: boolean;
  car: CarData;
}) => {
  const [isPending, setIsPending] = useState(false);

  const carForm = useForm<z.infer<typeof editCarSchema>>({
    resolver: zodResolver(editCarSchema),
    defaultValues: {
      name: car?.name,
    },
  });

  const onSubmit = async (values: z.infer<typeof editCarSchema>) => {
    setIsPending(true);
    try {
      await editCar({
        name: values?.name || car.name,
        id: car?.id || car.name,
        carImage: values?.carImage,
      });
      getCars();
      toast.success("Car Edited Successfully");
      setIsPending(false);
      setEditModalOpen(false);
      carForm.reset();
    } catch (error: any) {
      toast.error(error.code);
      setIsPending(false);
    }
  };
  return (
    <Dialog
      open={editModalOpen}
      onOpenChange={(open) => {
        setEditModalOpen(open);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editing {car?.name}</DialogTitle>
          <DialogDescription className="mt-10">
            Edit the car information
          </DialogDescription>
        </DialogHeader>

        <img
          src={car?.image_url}
          alt={car?.name}
          className="h-[200px] w-[200px] rounded-xl object-contain m-auto  transition-all duration-300 hover:scale-125 hover:rotate-6 cursor-zoom-in"
        />

        <Form {...carForm}>
          <form onSubmit={carForm.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={carForm.control}
              name="name"
              render={({ field }: { field: any }) => (
                <FormItem>
                  <FormLabel>Car Name</FormLabel>
                  <FormControl>
                    <Input placeholder={car.name} type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={carForm.control}
              name="carImage"
              render={({
                field: { value, onChange, ...fieldProps },
              }: {
                field: any;
              }) => (
                <FormItem>
                  <FormLabel>Select Car Image</FormLabel>
                  <FormControl>
                    <Input
                      type="file"
                      accept="image/png, image/jpg, image/jpeg, image/webp"
                      {...fieldProps}
                      onChange={(e) => {
                        onChange(e.target.files && e.target.files[0]);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" className="w-full" disabled={isPending}>
              {isPending ? "Saving..." : "Save"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EditCar;
