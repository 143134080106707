import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../assets/logo.svg";
import { Avatar, AvatarFallback, AvatarImage } from "../@/components/ui/avatar";
import { toast } from "sonner";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "../@/components/ui/dropdown-menu";
import { signOut } from "../utils/auth";
import { Button } from "../@/components/ui/button";
import { Dialog, DialogTrigger } from "../@/components/ui/dialog";
import AddCar from "../components/AddCar";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../@/components/ui/pagination";
import {
  getAllCars,
  getTheGamePercentage,
  getTheShareUrl,
  getTheTimer,
} from "../utils/carApis";
import { Skeleton } from "../@/components/ui/skeleton";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
} from "../@/components/ui/card";
import DeleteCar from "../components/DeleteCar";
import EditCar from "../components/EditCar";
import EditTimer from "../components/EditTimer";
import { Input } from "../@/components/ui/input";
import EditShareUrl from "../components/EditShareUrl";
import EditCorrectPercentage from "../components/EditCorrectPercentage";

export interface CarData {
  name: string;
  image_url: string;
  deleted: boolean;
  id?: string;
}

const Dashbaord = () => {
  const [user, setUser] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editTimerModalOpen, setEditTimerModalOpen] = useState(false);
  const [editCorrectPercentageModalOpen, setEditCorrectPercentageModalOpen] =
    useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [timer, setTimer] = useState(0);
  const [correctPercentage, setCorrectPercentage] = useState(0);
  const [shareUrl, setShareUrl] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const [car, setCar] = useState<null | CarData>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [cars, setCars] = useState<CarData[] | null>(null);
  const [searchCars, setSearchCars] = useState<CarData[] | null>(null);

  const navigate = useNavigate();

  const getTimer = async () => {
    try {
      const res = await getTheTimer();

      if (!res) {
        toast.warning("Could not retrieve timer from database");
        return;
      }

      setTimer(Number(res));
    } catch (error: any) {
      toast.error(error.code);
    }
  };

  const getCorrectPercentage = async () => {
    try {
      const res = await getTheGamePercentage();

      if (!res) {
        toast.warning(
          "Could not retrieve correct game percentage from database"
        );
        return;
      }

      setCorrectPercentage(Number(res));
    } catch (error: any) {
      toast.error(error.code);
    }
  };

  const getShareUrl = async () => {
    try {
      const res = await getTheShareUrl();

      if (!res) {
        toast.warning("Could not retrieve the share URL from database");
        return;
      }

      setShareUrl(res.toString());
    } catch (error: any) {
      toast.error(error.code);
    }
  };
  const getCars = async () => {
    setCurrentPage(1);
    setCars(null);
    try {
      const res = await getAllCars();

      if (!res || !Array.isArray(res)) {
        setCars([]);
        setTotalPages(1);
        return;
      }

      setCars(res);
      setTotalPages(Math.max(1, Math.ceil(res.length / 20)));
    } catch (error: any) {
      toast.error(error.code);
    }
  };
  useLayoutEffect(() => {
    const user =
      JSON.parse(sessionStorage.getItem("car-guesser-user") || "null") || null;

    if (!user) {
      toast.info("Sign In to access dashboard");
      return navigate("/");
    }

    setUser(user);
    getCars();
    getTimer();
    getShareUrl();
    getCorrectPercentage();

    // eslint-disable-next-line
  }, []);

  const handleDelete = (car: CarData) => {
    setCar(() => car);
    setDeleteModalOpen(true);
  };

  const handleEdit = (car: CarData) => {
    setCar(() => car);
    setEditModalOpen(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  useEffect(() => {
    if (!cars) return;

    if (searchParams.get("search")) {
      search(searchParams.get("search") as string);
    } else {
      setTotalPages(Math.max(1, Math.ceil(cars.length / 20)));
    }

    // eslint-disable-next-line
  }, [searchParams, cars]);

  const search = (nameKey: string) => {
    setSearchCars([]);
    setCurrentPage(1);

    if (!cars) return;
    for (let i = 0; i < cars.length; i++) {
      if (cars[i].name.toLowerCase().includes(nameKey.toLowerCase())) {
        setSearchCars((prev) => [...(prev || []), cars[i]]);
        setTotalPages(
          Math.max(1, Math.ceil(searchCars ? searchCars.length / 20 : 1))
        );
      }
    }
  };

  return (
    <>
      <DeleteCar
        car={car as CarData}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        getCars={getCars}
      />
      <EditCar
        car={car as CarData}
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        getCars={getCars}
      />
      <EditTimer
        timer={timer}
        editTimerModalOpen={editTimerModalOpen}
        setEditTimerModalOpen={setEditTimerModalOpen}
        getTimer={getTimer}
      />
      <EditCorrectPercentage
        correctPercentage={correctPercentage}
        editCorrectPercentageModalOpen={editCorrectPercentageModalOpen}
        setEditCorrectPercentageModalOpen={setEditCorrectPercentageModalOpen}
        getCorrectPercentage={getCorrectPercentage}
      />
      <EditShareUrl
        shareUrl={shareUrl}
        shareModalOpen={shareModalOpen}
        setShareModalOpen={setShareModalOpen}
        getShareUrl={getShareUrl}
      />
      <Dialog
        open={modalOpen}
        onOpenChange={(open) => {
          setModalOpen(open);
        }}
      >
        <AddCar setModalOpen={setModalOpen} getCars={getCars} />
        <div className="flex flex-col gap-10 bg-slate-100 min-h-[100vh] pb-20">
          <div className="w-full h-20 overflow-auto bg-white flex text-white flex-row items-center md:px-20 px-10 justify-between">
            <p className="text-xl font-semibold text-center text-primary flex flex-row gap-2 items-center">
              <img src={logo} className="w-8 h-8 object-cover" alt="" />
              <span>Car Guesser</span>
            </p>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar className="cursor-pointer">
                  <AvatarImage src={user?.providerData[0]?.photoURL} />
                  <AvatarFallback className="bg-primary text-white">
                    {user?.email[0].toUpperCase()}
                    {user?.email[1].toUpperCase()}
                  </AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>

              <DropdownMenuContent className="w-20">
                <DropdownMenuRadioItem
                  value="logout"
                  className="cursor-pointer"
                  onClick={async () => {
                    try {
                      await signOut();
                      sessionStorage.removeItem("car-guesser-user");
                      toast.success("Logged out successfully");
                      navigate("/");
                    } catch (error: any) {
                      toast.error(error.message);
                    }
                  }}
                >
                  Logout
                </DropdownMenuRadioItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          <div className="w-full md:px-20 px-10 flex flex-col gap-10">
            <Input
              placeholder={"Search for a car"}
              type="search"
              onChange={(e) => {
                searchParams.set("search", e.target.value);
                setSearchParams(searchParams);

                const resultObject = search(e.target.value);
                console.log(resultObject);
              }}
              value={searchParams.get("search") as string}
            />
          </div>

          <div className="w-full md:px-20 px-10 flex flex-col gap-10">
            <div className="flex sm:justify-end justify-start items-start flex-col sm:flex-row gap-2 flex-wrap">
              <DialogTrigger>
                <Button className="w-max">Upload a Car</Button>
              </DialogTrigger>

              <Button
                variant={"outline"}
                className="w-max"
                onClick={() => setEditTimerModalOpen(true)}
                disabled={!timer}
              >
                Change game timer
              </Button>
              <Button
                variant={"outline"}
                className="w-max"
                onClick={() => setShareModalOpen(true)}
                disabled={!timer}
              >
                Change share URL
              </Button>
              <Button
                variant={"outline"}
                className="w-max"
                onClick={() => setEditCorrectPercentageModalOpen(true)}
                disabled={!timer}
              >
                Change game percentage
              </Button>
            </div>

            <p className="text-xl font-semibold text-center text-primary flex sm:flex-row flex-col justify-between gap-2 sm:items-center items-start">
              <span>
                {searchParams.get("search")
                  ? `Search Result for ${searchParams.get("search")}`
                  : "All Cars"}
              </span>
              <span className="text-xs text-slate-500 font-normal">
                {searchParams.get("search") ? "Returned" : "Total"} Cars:{" "}
                {searchCars ? searchCars.length : cars && cars.length} - Page{" "}
                {currentPage} out of {totalPages}
              </span>
            </p>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full gap-4">
              {!cars &&
                Array.from({ length: 10 }, (_, index: number) => (
                  <Card className="flex flex-col space-y-3 p-6" key={index}>
                    <Skeleton className="h-[200px] rounded-xl" />
                    <div className="space-y-2">
                      <Skeleton className="h-4 my-4" />
                      <div className="flex flex-row gap-2 pt-4">
                        <Skeleton className="h-8 w-1/2" />
                        <Skeleton className="h-8 w-1/2" />
                      </div>
                    </div>
                  </Card>
                ))}
              {!searchParams.get("search")
                ? cars &&
                  cars
                    .slice((currentPage - 1) * 20, currentPage * 20)
                    .map((car, index) => (
                      <Card className="flex flex-col space-y-3" key={index}>
                        <CardHeader>
                          <img
                            src={car.image_url}
                            alt={car.name}
                            className="h-[200px] w-[250px] rounded-xl object-contain m-auto transition-all duration-300 hover:scale-125 hover:rotate-6 cursor-zoom-in"
                          />
                        </CardHeader>
                        <CardContent className="space-y-2">
                          <CardDescription className="font-semibold">
                            {car.name}
                          </CardDescription>
                        </CardContent>
                        <CardFooter className="flex flex-row gap-2">
                          <Button
                            variant="outline"
                            className="w-1/2"
                            onClick={() => handleEdit(car)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="destructive"
                            className="w-1/2"
                            onClick={() => handleDelete(car)}
                          >
                            Delete
                          </Button>
                        </CardFooter>
                      </Card>
                    ))
                : searchCars &&
                  searchCars
                    .slice((currentPage - 1) * 20, currentPage * 20)
                    .map((car, index) => (
                      <Card className="flex flex-col space-y-3" key={index}>
                        <CardHeader>
                          <img
                            src={car.image_url}
                            alt={car.name}
                            className="h-[200px] w-[250px] rounded-xl object-contain m-auto transition-all duration-300 hover:scale-125 hover:rotate-6 cursor-zoom-in"
                          />
                        </CardHeader>
                        <CardContent className="space-y-2">
                          <CardDescription className="font-semibold">
                            {car.name}
                          </CardDescription>
                        </CardContent>
                        <CardFooter className="flex flex-row gap-2">
                          <Button
                            variant="outline"
                            className="w-1/2"
                            onClick={() => handleEdit(car)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="destructive"
                            className="w-1/2"
                            onClick={() => handleDelete(car)}
                          >
                            Delete
                          </Button>
                        </CardFooter>
                      </Card>
                    ))}
            </div>

            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    className="cursor-pointer"
                    onClick={() => {
                      if (currentPage === 1) return;
                      setCurrentPage((page) => page - 1);

                      const tempCars = searchCars ? searchCars : cars;
                      searchParams.get("search")
                        ? setSearchCars(null)
                        : setCars(null);

                      setTimeout(() => {
                        searchParams.get("search")
                          ? setSearchCars(tempCars)
                          : setCars(tempCars);
                      }, 1500);
                    }}
                  />
                </PaginationItem>
                <PaginationItem>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <PaginationLink
                      className="cursor-pointer"
                      key={index}
                      onClick={() => {
                        if (currentPage === index + 1) return;
                        setCurrentPage(index + 1);

                        const tempCars = searchCars ? searchCars : cars;
                        searchParams.get("search")
                          ? setSearchCars(null)
                          : setCars(null);

                        setTimeout(() => {
                          searchParams.get("search")
                            ? setSearchCars(tempCars)
                            : setCars(tempCars);
                        }, 1500);
                      }}
                      isActive={currentPage === index + 1}
                    >
                      {index + 1}
                    </PaginationLink>
                  ))}
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    className="cursor-pointer"
                    onClick={() => {
                      if (currentPage === totalPages) return;
                      setCurrentPage((page) => page + 1);

                      const tempCars = searchCars ? searchCars : cars;
                      searchParams.get("search")
                        ? setSearchCars(null)
                        : setCars(null);

                      setTimeout(() => {
                        searchParams.get("search")
                          ? setSearchCars(tempCars)
                          : setCars(tempCars);
                      }, 1500);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default Dashbaord;
