import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { editTimer } from "../utils/carApis";
import { toast } from "sonner";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../@/components/ui/form";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
import { timerSchema } from "../utils/schema";
const EditTimer = ({
  setEditTimerModalOpen,
  editTimerModalOpen,
  timer,
  getTimer,
}: {
  setEditTimerModalOpen: Dispatch<SetStateAction<boolean>>;
  editTimerModalOpen: boolean;
  timer: number;
  getTimer: () => void;
}) => {
  const [isPending, setIsPending] = useState(false);

  const timerForm = useForm<z.infer<typeof timerSchema>>({
    resolver: zodResolver(timerSchema),
  });

  const onSubmit = async (values: z.infer<typeof timerSchema>) => {
    setIsPending(true);
    try {
      await editTimer(values.time);

      toast.success("Timer Edited Successfully");
      getTimer();
      setIsPending(false);
      setEditTimerModalOpen(false);
      timerForm.reset();
    } catch (error: any) {
      toast.error(error.code);
      setIsPending(false);
    }
  };
  return (
    <Dialog
      open={editTimerModalOpen}
      onOpenChange={(open) => {
        setEditTimerModalOpen(open);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Editing Timer</DialogTitle>
          <DialogDescription className="mt-10">
            Edit the timer that allows users to answer a question
          </DialogDescription>
        </DialogHeader>

        <Form {...timerForm}>
          <form
            onSubmit={timerForm.handleSubmit(onSubmit)}
            className="space-y-8"
          >
            <FormField
              control={timerForm.control}
              name="time"
              render={({ field }: { field: any }) => (
                <FormItem>
                  <FormLabel>Time (in secs)</FormLabel>
                  <FormControl>
                    <Input placeholder={timer} type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" className="w-full" disabled={isPending}>
              {isPending ? "Saving..." : "Save"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EditTimer;
